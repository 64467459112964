h1 {
    margin-bottom: 32px;
    margin-top: 1.19em;
    letter-spacing: -0.011em;
    line-height: 42px;
    font-size: 32px !important;
    font-style: normal;
    font-weight: 700;
    color: #242424;
    font-family: "sohne", "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin: 0;
}

.content-about {    
    max-width: 680px;
    margin: 0 24px;
    min-width: 0;
    /* width: 100%; */
    display: block;
    overflow-wrap: break-word;
    word-break: break-word;
    font-weight: 400;
    margin-left: auto;
    margin-right: auto;
    /* margin-bottom: 32px; */
    min-height: 100vh;
}

.content-about {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
}

.content-about p {
    text-indent: 2em;
    color: #242424;
    font-family: "sohne", "Helvetica Neue", Helvetica, Arial, sans-serif;
    -webkit-tap-highlight-color: transparent;
    color: inherit;
    text-decoration: none;
    box-sizing: inherit;
    font-size: 1em;
    line-height: 20px;
    font-weight: 400;
    color: #6B6B6B;
    margin: 0;
    margin-bottom: 0.5em;
}

nav ul {
    list-style-type: none; /* Remove os bullets */
    padding: 0; /* Remove o padding padrão */
    display: flex; /* Exibe os itens de lista lado a lado */
    flex-wrap: nowrap;
    justify-content: center;
}

nav ul li {
    padding: 0 10px; /* Adiciona um padding entre os itens de lista */
    cursor: pointer;
    font-family: 'Courier New', Courier, monospace; /* fonte estilo terminal */
    font-weight: 800;
    font-size: 1em;
    display: flex; /* Transforma o item de lista em um container flex */
    justify-content: center; /* Centraliza o conteúdo horizontalmente */
    align-items: center; /* Centraliza o conteúdo verticalmente */
    color: #000;
    min-height: 1.5em;
    line-height: 1.5em;
    text-align: center;
    box-sizing: border-box;
}

nav ul li a {
    display: block;
    height: 100%;
    width: 100%;
}

nav ul a {
    text-decoration: none;
    color: inherit;
}

/* Estilos para dispositivos móveis */
@media (max-width: 768px) {
    .content-about {
        margin: 0 24px;
        justify-content: space-between; /* Adiciona espaço entre os itens */
    }

    .visit-icon {
        display: block;
        margin-top: 10px;
    }

    .back-link-bottom {
        display: block;
        padding-top: 32px;
    }

    .content-about h1 {
        margin-top: 100px;
    }

    .content-about p {
        text-indent: 2em;
    }

    nav ul {
        list-style-type: none; /* Remove os bullets */
        padding: 0; /* Remove o padding padrão */
        display: flex; /* Exibe os itens de lista lado a lado */
        flex-direction: column;
        align-items: center;
        margin-bottom: 100px;
    }

    nav ul li {
        padding: 10px 10px; /* Adiciona um padding entre os itens de lista */
        cursor: pointer;
        font-family: 'Courier New', Courier, monospace; /* fonte estilo terminal */
        font-weight: 800;
        font-size: 1em;

    }
}