.content-contact {    
    max-width: 400px;
    margin: 0 24px;
    min-width: 0;
    /* width: 100%; */
    display: block;
    overflow-wrap: break-word;
    word-break: break-word;
    font-weight: 400;
    margin-left: auto;
    margin-right: auto;
    /* margin-bottom: 32px; */
    min-height: 100vh;
}

.content-contact {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
}

.content-contact h1 {
    margin-bottom: 32px;
}

.content-contact {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content-contact p {
    /* text-indent: 2em; */
    color: #242424;
    font-family: "sohne", "Helvetica Neue", Helvetica, Arial, sans-serif;
    -webkit-tap-highlight-color: transparent;
    color: inherit;
    text-decoration: none;
    box-sizing: inherit;
    font-size: 1em;
    line-height: 20px;
    font-weight: 400;
    color: #6B6B6B;
    margin: 0;
    margin-bottom: 0.5em;
    text-align: center;
}

form {
    display: flex;
    flex-direction: column;
    width: 400px; /* Ajuste conforme necessário */
    margin-top: 20px;
}

input, textarea {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

textarea {
    min-height: 200px;
}

/* button {
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #4CAF50; 
    color: white;
    cursor: pointer;
}

button:hover {
    background-color: #45a049; 
} */

button {
    padding: 0 0px 0px 0; /* Adiciona um padding entre os itens de lista */
    cursor: pointer;
    font-family: 'Courier New', Courier, monospace; /* fonte estilo terminal */
    font-weight: 800;
    font-size: 1.3em;
    display: flex; /* Transforma o item de lista em um container flex */
    justify-content: center; /* Centraliza o conteúdo horizontalmente */
    align-items: center; /* Centraliza o conteúdo verticalmente */
    background-color: transparent;
    border: none;
    margin-top: 1rem;
    /* text-decoration: underline; */
    display: inline-block;
    /* border-bottom: 2px solid; */
    /* border-right: 2px solid; */
    margin-bottom: 50px;
}

/* button:hover {
    background-color: #45a049; 
} */

.recaptcha, .feedback {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    height: 80px;
}

/* .feedback {
    text-align: center;
} */

.feedback.success {
    color: green;
}

.feedback.error {
    color: red;
}

/* Estilos para dispositivos móveis */
@media (max-width: 768px) {
    .content-contact {
        margin: 0 24px;
        justify-content: space-between; /* Adiciona espaço entre os itens */
    }

    .back-link-contact {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        margin: 20px;
        padding: 0;
        background: #fff;
    }

    form {
        display: flex;
        flex-direction: column;
        width: 100%; /* Ajuste conforme necessário */
        margin-top: 20px;
    }
}