.content-projects {    
    max-width: 680px;
    margin: 0 24px;
    padding: 32px 0;
    min-width: 0;
    /* width: 100%; */
    display: block;
    overflow-wrap: break-word;
    word-break: break-word;
    font-weight: 400;
    margin-left: auto;
    margin-right: auto;
    /* margin-bottom: 32px; */
    min-height: 100vh;
}

.content-projects {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
}

.content-projects h1 {
    margin-bottom: 32px;
}

.content-projects p {
    text-indent: 2em;
    color: #242424;
    font-family: "sohne", "Helvetica Neue", Helvetica, Arial, sans-serif;
    -webkit-tap-highlight-color: transparent;
    color: inherit;
    text-decoration: none;
    box-sizing: inherit;
    font-size: 1em;
    line-height: 20px;
    font-weight: 400;
    color: #6B6B6B;
    margin: 0;
    margin-bottom: 0.5em;
}

.project-list {
    margin-top: 10px;
}

.project {
    display: flex;
    width: 100%; /* Largura fixa */
    margin-bottom: 20px;    
    padding-top: 20px;
    padding-bottom: 40px;
    border-bottom: 2px solid rgb(242, 242, 242);
}

.project p {
    text-indent: 0;
}

.project-image {
    width: 300px;
    height: 240px;
    background-color: gray;
    margin-right: 20px;
}

.project-image {
    background-position: top; /* Alinha a imagem de fundo ao topo */
    background-repeat: no-repeat; /* Evita que a imagem de fundo se repita */
    background-size: cover; /* Faz a imagem de fundo cobrir todo o elemento */
    height: 265px; /* Define a altura do elemento */
    transition: background-position 4s ease-in-out; /* Adiciona uma transição à propriedade background-position */
}

.project-image:hover {
    background-position: bottom; /* Muda a posição da imagem de fundo para o fundo quando o mouse passa sobre o elemento */
}

.project-details {
    flex-grow: 1;
    white-space: nowrap; /* Impede que o texto quebre para a próxima linha */
    overflow: hidden; /* Esconde o texto que excede a largura do container */
    text-overflow: ellipsis; /* Adiciona "..." ao final do texto cortado */
}

.project-title {
    margin: 0;
    margin-bottom: 10px;
    font-size: 1.5em;
}

.project-description {
    margin: 0;
    margin-bottom: 10px;
}

.project-tags {
    display: flex;
    flex-wrap: wrap;
}

.tag {
    display: inline-block;
    padding: 5px 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    border: 1px solid #000;
    border-radius: 5px;
}

.project-title {
    width: 400px; 
    height: 40px; 
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    /* display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;  
    overflow: hidden; */
}

.project-section {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start; /* align to the top */
}

.project-section .btnExpand {
    position: absolute;
    right: 0;
    align-self: flex-end;
    cursor: pointer;
}

.project-description {
    width: 400px; 
    height: 125px; 
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
} 

.project-description a {
    text-decoration: underline;
}

.project-tags {
    width: 400px; 
    height: 80px; 
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* .project-title, .project-description, .project-tags {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
} */
  
  .project-title.expanded, .project-description.expanded, .project-tags.expanded {
    height: auto;
    white-space: normal;
  }

/* Estilos para dispositivos móveis */
@media (max-width: 768px) {
    .content-projects {
        margin: 0 24px;
        justify-content: space-between; /* Adiciona espaço entre os itens */
        padding: 0;
    }

    .project {
        display: flex;
        flex-direction: column;
        width: 100%; /* Ajuste conforme necessário */
        margin-top: 0px;
        justify-content: center;
        align-items: center;
        padding-bottom: 20px;
    }

    .project-image {
        margin-right: 0;
    }

    .project-details {
        margin-top: 20px;
        width: 300px;
    }

    .project-description {
        margin-top: 20px;
        height: 170px;
    }

    .project-section-tags {
        flex-direction: column;
        align-items: flex-start;
        width: 300px;
        white-space: normal;
    }

    .project-tags {
        width: 280px; 
        height: 130px; 
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: wrap;
    }

    .project-section-tags .btnExpand {
        margin-top: 20px;
        position: relative;
        align-self: center;
    }

    .tag {
        text-align: center;
    }

    .back-link-bottom-proj {
        padding-bottom: 32px;
    }
}