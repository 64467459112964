/* h1, h2, h3, h4, h5, h6, dl, dd, ol, ul, menu, figure, blockquote, p, pre, form {
    margin: 0;
} */

/* body {
    background-color: #fff;
    font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #6B6B6B;
} */

.articles-menu, .articles-ol, .articles-ul {
    padding: 0;
    /* list-style: none; */
    /* list-style-image: none; */
}

.outer-div {
    background-color: #fff; /* Substitua por sua cor de fundo preferida */
    font-family: "sohne", "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #6B6B6B;
}

.content-articles {    
    max-width: 680px;
    margin: 0 24px;
    padding: 32px 0;
    min-width: 0;
    /* width: 100%; */
    display: block;
    overflow-wrap: break-word;
    word-break: break-word;
    font-weight: 400;
    margin-left: auto;
    margin-right: auto;
    /* margin-bottom: 32px; */
    min-height: 100vh;
}

.content-articles {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; 
}

.content-articles-p {
    text-indent: 2em;
    color: #242424;
    font-family: "sohne", "Helvetica Neue", Helvetica, Arial, sans-serif;
    -webkit-tap-highlight-color: transparent;
    color: inherit;
    text-decoration: none;
    box-sizing: inherit;
    font-size: 1em;
    line-height: 20px;
    font-weight: 400;
    color: #6B6B6B;
    margin: 0;
    margin-bottom: 0.3em;
}

.visit-icon {
    display: inline-block;
    margin-top: 10px;
}

.only-mobile {
    display: none;
}

.article-snippet {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 2px solid rgb(242, 242, 242);
    display: block;
    font-weight: 400;
}

.article-snippet h3 {
    -webkit-line-clamp: 2;
    max-height: 40px;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0;
    color: #242424;
    line-height: 20px;
    margin: 0;
    margin-block-start: 1em;
    margin-block-end: 0.5em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.article-snippet p {
    -webkit-tap-highlight-color: transparent;
    color: inherit;
    text-decoration: none;
    box-sizing: inherit;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    color: #6B6B6B;
    margin: 0;
    margin-bottom: 0.5em;
}

.article-snippet p a {
    text-decoration: none;
}

.article-snippet span {
    color: #6B6B6B;
    line-height: 20px;
    font-size: 14px;
    font-weight: 400;
}

/* .content {
    max-width: 680px;
    margin: 0 24px;
    min-width: 0;
    width: 100%;
    display: flex; 
    justify-content: center;
    align-items: center;
    overflow-wrap: break-word;
    word-break: break-word;
    font-weight: 400;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 32px;
    height: 100vh; 
} */

.back-link-article {
    cursor: pointer;
    margin-top: 32px;
    margin-bottom: 32px;
}

.articles-p a,
.articles-figcaption a {
    text-decoration: underline;
}

.author {
    margin-top: 32px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    max-width: 680px;
    display: block;
    overflow-wrap: break-word;
    word-break: break-word;
    font-weight: 400;
    font-size: 20px;
    font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;
    line-height: 20px;
    color: #6B6B6B;
}

.visit {
    padding-top: 32px;
    margin-top: 32px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    max-width: 680px;
    display: block;
    overflow-wrap: break-word;
    word-break: break-word;
    font-weight: 400;
    font-size: 20px;
    font-family: "sohne", "Helvetica Neue", Helvetica, Arial, sans-serif;
    line-height: 20px;
    color: #6B6B6B;
    border-top: 2px solid rgb(242, 242, 242);
}

.articles-h1 {
    margin-bottom: 32px;
    margin-top: 1.19em;
    letter-spacing: -0.011em;
    line-height: 42px;
    font-size: 32px !important;
    font-style: normal;
    font-weight: 700;
    color: #242424;
    font-family: "sohne", "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin: 0;
    text-align: center;
}

.article-h1 {
    margin-bottom: 32px !important;
    margin-top: 1.19em !important;
    letter-spacing: -0.011em;
    line-height: 42px;
    font-size: 32px !important;
    font-style: normal;
    font-weight: 700;
    color: #242424;
    font-family: "sohne", "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin: 0;
}

.articles-figure {
    margin: 0;
    padding: 0;
    display: block;
    position: relative;
    margin-top: 32px;
    margin-right: auto;
    margin-left: auto;
    width: 100%;    
}

.articles-img {
    /* cursor: zoom-in; */
    overflow-wrap: break-word;
    word-break: break-word;
    width: 100%;
    display: block;
    margin: auto;
}

.articles-figcaption {
    max-width: 728px;
    text-align: center;
    margin-top: 10px;
    margin-right: auto;
    margin-left: auto;
    color: #6B6B6B;
    font-size: 14px;
    font-family: "sohne", "Helvetica Neue", Helvetica, Arial, sans-serif;
    line-height: 20px;
    font-weight: 400;
}

.articles-p {
    letter-spacing: -0.003em;
    line-height: 32px;
    margin-top: 2.14em;
    font-size: 20px;
    margin-bottom: -0.46em;
    font-family: source-serif-pro, Georgia, Cambria, "Times New Roman", Times, serif;
    font-style: normal;
    word-break: break-word;
    color: #242424;
    font-weight: 400;
    
}

.articles-h3, .articles-h4 {
    letter-spacing: 0;
    /* line-height: 24px; */
    margin-top: 1.72em;
    font-size: 20px !important;
    margin-bottom: -0.31em;
    font-weight: 600;
    font-style: normal;
    color: #242424;
    font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;
    /* margin: 0; */
    display: block;
    font-size: 1.5em;
    /* margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px; */
}

.articles-li {
    letter-spacing: -0.003em;
    line-height: 32px;
    margin-top: 2.14em;
    font-size: 20px;
    padding-left: 0px;
    margin-left: 20px;
    margin-bottom: -0.46em;
    /* list-style-type: disc; */
    font-family: source-serif-pro, Georgia, Cambria, "Times New Roman", Times, serif;
    font-style: normal;
    color: #242424;
    font-weight: 400;
    display: list-item;
    text-align: -webkit-match-parent;
    word-wrap: break-word;
    word-break: break-word;
}

pre {
    margin-top: 1.19em;
    margin-bottom: 1.19em;
    font-size: 16px;
    font-family: Consolas, "Courier New", monospace;
    line-height: 24px;
    color: #242424;
    background-color: rgb(249, 249, 249);
    font-weight: 400;
    overflow-wrap: break-word;
    word-break: break-word;
    padding: 16px;
    max-width: 650px;
    overflow: auto; 
    /* max-width: 100%; */
    box-sizing: border-box;
}

/* Estilos para dispositivos móveis */
@media (max-width: 768px) {
    .back-link {
        display: block;
        position: relative;
        top: -20px;
        left: -20px;
        /* margin: 20px; */
        padding: 20px 0 10px 20px;
        background: #fff;
    }

    .content-articles {
        margin: 0 24px;
    }

    .visit-icon {
        display: block;
        margin-top: 10px;
    }

    .back-link-bottom {
        display: block;
        padding-top: 32px;
    }
    pre {
        max-width: 450px;
        margin-left: auto;
        margin-right: auto;
    }
}
@media (max-width: 480px) {
    pre {
        max-width: 300px;
        margin-left: auto;
        margin-right: auto;
    }
}