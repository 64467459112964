/* General Styles */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
}

h1, h2, h3, h4, p {
    margin: 0;
    padding: 0;
}

.resume {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    font-size: 12pt;
}

.resume-h1 {
    font-size: 15pt !important;
}

.resume-h2 {
    font-size: 12pt !important;
    margin-top: 10px;
}

.resume-h3 {
    font-size: 14pt !important;
    margin: 0;
}

.resume-h4 {
    font-size: 12pt !important;
    /* margin-top: 15px; */
}

/* p, li {
    font-size: 12pt;
} */

/* Header styles */
.header {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    /* border-bottom: 1px solid #ddd; */
    padding-bottom: 10px;
}

.header-left {
    flex: 1;
    text-align: left;
}

.header-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
}

.header a {
    margin: 0 5px;
    text-decoration: none;
    color: #0073e6;
}

.header p {
    margin-top: 5px;
}

/* Summary styles */
.summary {
    margin: 20px 0;
    /* border-top: 1px solid #ddd; */
    /* border-bottom: 1px solid #ddd; */
    padding: 10px 0;
}

/* Competencies styles */
.competencies {
    margin-top: 20px;
}

.competencies ul {
    list-style-type: circle;
    padding-left: 20px;
}

/* Work Experience styles */
.experience {
    margin-top: 20px;
}

.job-description {
    margin-top: 10px;
    margin-right: 20px;
    /* border-top: 1px dashed #ddd; */
    padding-top: 10px;
}

.job-description ul {
    list-style-type: square;
    margin: 0;
    padding-left: 20px;
}

.interests ul {
    list-style-type: none;
    margin: 0;
    padding-left: 0px;
    padding-top:20px;
}

.contact-item {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}

.contact-item img {
    margin-right: 10px; /* Ajuste conforme necessário */
    margin-left: 10px; /* Ajuste conforme necessário */
}

.multi-column {
    -webkit-column-count: 2; /* Chrome, Safari, Opera */
    -moz-column-count: 2;    /* Firefox */
    column-count: 2;         /* padrão */

    -webkit-column-gap: 40px; /* Chrome, Safari, Opera */
    -moz-column-gap: 40px;    /* Firefox */
    column-gap: 40px;         /* padrão */

    max-height: 500px; /* Ajuste conforme necessário */
    overflow: auto;
}

.show-mobile {
    display: none;
}

.not-mobile {
    display: block;
}

.resume-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.resume-col {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.back-link {
    position: fixed;
    top: 20px;
    left: 20px;
    cursor: pointer;
    /* margin: 20px; */
    /* padding: 20px; */
    /* background: #fff; */
    color: #000;
}

.back-link a {
    text-decoration: none;
    color: #000;
    font-weight: bold;
}


/* Estilos para dispositivos móveis */
@media (max-width: 768px) {
    .header {
        flex-direction: column;
    }

    .header > div {
        width: 100%;
    }

    .header-right {
        text-align: left;
        align-items: flex-start;
        padding-top: 20px;
    }

    .header-right img {
        margin-left: -10px
    }

    .contact-item {
        flex-direction: row;
    }

    .contact-item img {
        margin-right: 10px;
        margin-left: 0px;
    }

    .show-mobile {
        display: block;
    }

    .not-mobile {
        display: none;
    }

    .resume-row {
        margin-top: 20px;
        flex-direction: column;
    }

    .resume-row  > div {
        width: 100%;
    }

    .back-link {
        display: block;
        position: relative;
        top: -20px;
        left: -20px;
        /* margin: 20px; */
        padding: 20px;
        background: #fff;
    }
}