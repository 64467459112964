.center {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-family: 'Courier New', Courier, monospace; /* fonte estilo terminal */
    font-size: 1.5em;
    font-weight: 800;
    cursor: default;
    user-select: none;
    /* color: #00FF00; cor verde de terminal */
    /* background-color: #000; fundo preto */
}

.center a {
    height: 1.5em;
    line-height: 1.5em;
    display: block;
}

@keyframes blink {
    0%, 100% { opacity: 1; }
    50% { opacity: 0; }
}

.blink {
    animation: blink 1s infinite;
}

/* .blink {
    background-image: url('../../public/rabbit.svg'); 
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
}

.blink::before {
    content: '_';
    display: block;
    animation: blink 1s infinite;
}

.center:hover .blink::before {
    color: transparent;
} */
a {
    color: inherit;        /* Remove a cor padrão do link e herda a cor do elemento pai */
    text-decoration: none; /* Remove o sublinhado do link */
    cursor: pointer;          /* Define o cursor para o padrão em vez do ponteiro de link */
}
